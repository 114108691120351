@import '~@angular/material/theming';

// Custom Theming and Variables for OneAngular Kit
@import '~@one/angular-kit/styles/theming';
// Include the OneDesign Icons. We include this here so that you only
// have to load a single css file for OneDesign Icons in your app.
// Be sure that you only ever include this mixin once!
@import '~@one/icons/dist/one-icons-legacy.css';

// This will automatically load in the Roboto Fontface.
// In case you want to use a different font, make sure to update
// they "body" tag and remove this line.
@import '~roboto-fontface/css/roboto/roboto-fontface.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include one-core();

// Additionally modifies components for the OneAngularKit
@include one-angular-theme();

html,
body {
  margin: 0;
  font-family: 'Roche Sans', Roboto, 'Helvetica Neue', sans-serif!important;
  background-color: $one-color-cobas-gray-100;
}

.mat-button-toggle,
.mat-card,
.mat-list-item-content,
.mat-list-item,
.mat-list-option,
a {
  font-family: 'Roche Sans', Roboto, 'Helvetica Neue', sans-serif!important;
}

.mat-body-2, .mat-body-strong {
  font-size: 16px!important;
  font-family: 'Roche Sans', Roboto, 'Helvetica Neue', sans-serif!important;
  font-weight: 500;
}

/*
 * To be deleted when issue is solved in OneAngular
 * https://code.roche.com/onedesign/angular-kit/issues/53
 */
.mat-dialog-content[oneDialogContent] {
  @media screen and (max-width: 599px) {
    min-width: unset;
  }
}
// To wrap the text in snackbar in mobile devices
one-snack-bar {
  .one-snack-bar-container {
    @media screen and (max-width: 599px) {
      min-width: unset !important;
    }
  }
}
// This is required here for live chat's tool tip styling
.mat-tooltip {
  font-size: 11px !important;
  margin-top: -2px !important;
}

// This is to set the width for side nav in desktop as per the prototype
.mat-drawer-inner-container {
  @media screen and (min-width: 599px) {
    width: 227px !important;
  }
}
// set background color in logged in view
mat-sidenav-content {
  background-color: $one-color-cobas-gray-100;
}

.page-content{
  @media (min-width: 599px) {
    padding: 117px 24px 24px 24px;
  }
  @media (max-width: 599px) {
    padding: 68px 24px 24px 24px;
  }
  position: relative;
}

.mat-progress-bar-fill:after,
.mat-fab.mat-accent, 
.mat-fab.mat-primary, 
.mat-flat-button.mat-accent:not([disabled]), 
.mat-flat-button.mat-primary:not([disabled]), 
.mat-mini-fab.mat-accent, 
.mat-mini-fab.mat-primary, 
.mat-raised-button.mat-accent:not([disabled]), 
.mat-raised-button.mat-primary:not([disabled]),
#nav-list-blue {
  background-color: $one-color-cobas-blue-600!important;
}

.mat-stroked-button:not([disabled]), 
.blue {
  color: $one-color-cobas-blue-600!important;
}

.one-tab-group 
.mat-tab-label.mat-tab-label-active, 
.one-tab-group .mat-tab-label.mat-tab-label-active .mat-icon, 
.one-tab-group .mat-tab-link.mat-tab-label-active, 
.one-tab-group .mat-tab-link.mat-tab-label-active .mat-icon, 
[one-tab-group] .mat-tab-label.mat-tab-label-active, 
[one-tab-group] .mat-tab-label.mat-tab-label-active .mat-icon, 
[one-tab-group] .mat-tab-link.mat-tab-label-active, 
[one-tab-group] .mat-tab-link.mat-tab-label-active .mat-icon {
  color: $one-color-cobas-blue-600!important;
}
